import React from 'react';
import _ from 'lodash';

import { GoogleSheetsDB } from '../../../api/GoogleSheetsDB';
import TopNavBar from '../ui/TopNavBar';
import Headline from '../ui/Headline';
import About from './About';
import Resume from '../resume/Resume';
import constants from '../../common/constants';

import './HomeContainer.css';

class HomeContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resumeData: [],
      educationData: [],
      backgroundData: [],
      loading: false
    };
  }

  async componentWillMount() {
    const sheets = new GoogleSheetsDB(constants.GOOGLE_SHEETS_KEY);

    this.setState({ loading: true });

    sheets.fetch({
      handleData: processedData => {
        const { education, background, resume } = processedData;

        this.setState({
          resumeData: resume,
          educationData: education,
          backgroundData: _.first(background)
        });
      }
    });

    this.setState({ loading: false });
  }

  render() {
    const { resumeData, educationData, backgroundData } = this.state;

    const { mainBackgroundImageUrl, profileImageurl, aboutMeText } =
      backgroundData || {};

    return (
      <div className="home-container">
        <TopNavBar />
        <div
          className="main-image"
          style={{
            backgroundImage: `url(${mainBackgroundImageUrl})`
          }}
        >
          <Headline backgroundData={backgroundData} />
          <div id="scroll-lower" className="scroll-lower">
            <a
              href="#about"
              style={{
                margin: 'auto',
                fontSize: '1.5em',
                textDecoration: 'none',
                color: 'white'
              }}
            >
              ↓
            </a>
          </div>
        </div>
        <div
          style={{
            position: 'relative',
            top: '100vh'
          }}
        >
          <About aboutMeText={aboutMeText} profileImageurl={profileImageurl} />
          <Resume resumeData={resumeData} educationData={educationData} />
        </div>
      </div>
    );
  }
}

export default HomeContainer;
