import React from 'react';
import _ from 'lodash';

import '../home/HomeContainer.css';

const LINKS = [
  {
    title: 'Home',
    id: '#'
  },
  {
    title: 'About',
    id: '#about'
  },
  {
    title: 'Resume',
    id: '#resume'
  },
  {
    title: 'Contact',
    id: '#contact'
  }
];

class TopNavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div id="top-nav-bar" className="nav-bar">
        <div style={{ margin: 'auto', display: 'flex' }}>
          {LINKS.map(({ title, id }) => (
            <strong key={title} style={{ margin: '1em', fontSize: '1.25em' }}>
              <a
                href={id}
                style={{
                  color: '#fff',
                  textDecoration: 'none'
                }}
              >
                {title}
              </a>
            </strong>
          ))}
        </div>
      </div>
    );
  }
}

export default TopNavBar;
