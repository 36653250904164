import React from 'react';
import _ from 'lodash';

import constants from '../../common/constants';

const Headline = ({ backgroundData }) => {
  const { name, headlineText } = backgroundData || {};

  return (
    <div
      id="headline"
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto auto auto auto',
        borderLeft: '3px solid whitesmoke',
        padding: '2em'
      }}
    >
      <span id="main-name" className="main-name">
        {name}
      </span>
      <span
        style={{
          textAlign: 'center',
          fontWeight: 500,
          color: 'white',
          textShadow: '0px 1px 3px rgba(0, 0, 0, 0.8)'
        }}
      >
        {(headlineText || '').split(constants.NEW_LINE_DELIMITER).map(l => (
          <p key={l}>{l}</p>
        ))}
      </span>
    </div>
  );
};

export default Headline;
