import React from 'react';

const About = ({ aboutMeText, profileImageurl }) => {
  return (
    <div
      id="about"
      style={{
        padding: '1em',
        display: 'flex',
        minHeight: '50vh',
        flexWrap: 'wrap',
        marginBottom: '1em'
      }}
    >
      <div style={{ flex: 1, display: 'flex' }}>
        <img
          src={profileImageurl}
          style={{
            borderRadius: '50%',
            objectFit: 'cover',
            height: '25em',
            width: '25em',
            margin: 'auto',
            border: '1px solid whitesmoke'
          }}
          alt=""
        />
      </div>
      <div
        style={{
          flex: 2,
          display: 'flex',
          flexDirection: 'column',
          padding: '2em'
        }}
      >
        <div style={{ margin: 'auto' }}>
          <h2>About Me</h2>
          <p style={{ fontSize: '1.2em' }}>{aboutMeText}</p>
        </div>
      </div>
    </div>
  );
};

export default About;
