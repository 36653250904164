import React from 'react';
import { DateTime } from 'luxon';

import constants from '../../common/constants';

const convertToDatetime = dateStr => {
  return DateTime.fromISO(dateStr);
};

const dateSort = (a, b, key) => {
  const convertedA = convertToDatetime(a[key]);
  const convertedB = convertToDatetime(b[key]);
  return convertedB.toMillis() - convertedA.toMillis();
};

class Resume extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { resumeData, educationData } = this.props;

    return (
      <div
        id="resume"
        style={{
          display: 'flex',
          flexDirection: 'column',
          padding: '1em 2em 1em 2em'
        }}
      >
        <div
          id="education"
          style={{
            display: 'flex',
            marginBottom: '2em',
            paddingBottom: '2em',
            borderBottom: '1px solid lightgray'
          }}
        >
          <div style={{ flex: 1, display: 'flex' }}>
            <span className="resume-header">Education</span>
          </div>
          <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
            {(educationData || [])
              .sort((a, b) => dateSort(a, b, 'graduationDate'))
              .map(e => {
                const { id, graduationDate, school, degreeAndDescription } = e;

                const converted = convertToDatetime(graduationDate);

                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '1em',
                      fontSize: '1.25em'
                    }}
                    key={id}
                  >
                    <div style={{ display: 'flex' }}>
                      <strong style={{ flex: 1 }}>{school}</strong>
                      <span style={{ flex: 1, textAlign: 'right' }}>
                        {converted.toFormat('MMM yyyy')}
                      </span>
                    </div>
                    <i>{degreeAndDescription}</i>
                  </div>
                );
              })}
          </div>
        </div>
        <div id="Experience" style={{ display: 'flex' }}>
          <div style={{ flex: 1, display: 'flex' }}>
            <span className="resume-header">Experience</span>
          </div>
          <div style={{ flex: 2, display: 'flex', flexDirection: 'column' }}>
            {(resumeData || [])
              .sort((a, b) => dateSort(a, b, 'dateStart'))
              .map(r => {
                const {
                  id,
                  description,
                  company,
                  location,
                  positionTitle,
                  dateStart,
                  dateEnd
                } = r;

                const startDate = convertToDatetime(dateStart);

                const endDateDisplay = dateEnd
                  ? convertToDatetime(dateEnd).toFormat('MMM yyyy')
                  : 'present';

                return (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: '1em',
                      fontSize: '1.25em'
                    }}
                    key={id}
                  >
                    <div style={{ display: 'flex' }}>
                      <strong style={{ flex: 1 }}>{company}</strong>
                      <span style={{ flex: 1, textAlign: 'right' }}>
                        {startDate.toFormat('MMM yyyy')} - {endDateDisplay}
                      </span>
                    </div>
                    <span style={{ textSize: 'smaller' }}>{location}</span>
                    <i style={{ textSize: 'smaller' }}>{positionTitle}</i>
                    <ul>
                      {(description || '')
                        .split(constants.NEW_LINE_DELIMITER)
                        .filter(l => l)
                        .map(bullet => (
                          <li key={bullet}>{bullet}</li>
                        ))}
                    </ul>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default Resume;
