import Tabletop from 'tabletop';

/**
 * fetch, parse, data from google sheet
 */
export class GoogleSheetsDB {
  constructor(sheetsKey) {
    this.key = sheetsKey;
  }

  processData(rawData) {
    return Object.keys(rawData).reduce((processedData, sheetName) => {
      const { elements } = rawData[sheetName];

      processedData[sheetName] = elements.map((r, idx) => ({
        ...r,
        id: `${sheetName}-${idx}`
      }));

      return processedData;
    }, {});
  }

  fetch({ handleData }) {
    Tabletop.init({
      key: this.key,
      callback: rawData => {
        handleData(this.processData(rawData));
      }
    });
  }
}
